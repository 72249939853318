/* styles/globals.css */

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bfc7ce;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/kastelov-axiforma-black.otf') format('opentype');
  font-weight: 800;
  font-display: 'swap';
}
@font-face {
  font-family: 'Axiforma';
  src: url('/fonts/kastelov-axiforma-regular.otf') format('opentype');
  font-weight: 400;
  font-display: 'swap';
}

@font-face {
  font-family: 'Helvetica Now Text';
  src: url('/fonts/helveticanowtext-normal.ttf') format('truetype');
  font-weight: 400;
  font-display: 'swap';
}

@font-face {
  font-family: 'Helvetica Now Text';
  src: url('/fonts/helveticanowtext-regular.ttf') format('truetype');
  font-weight: 500;
  font-display: 'swap';
}

@font-face {
  font-family: 'Helvetica Now Text';
  src: url('/fonts/helveticanowtext-medium.ttf') format('truetype');
  font-weight: 700;
  font-display: 'swap';
}

@font-face {
  font-family: 'Helvetica Now Text';
  src: url('/fonts/helveticanowtext-bold.ttf') format('truetype');
  font-weight: 800;
  font-display: 'swap';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Axiforma', sans-serif;
}

body,
p {
  font-family: 'Helvetica Now Text', sans-serif;
}
